import { HelpIcon } from 'icons/HelpIcon'
import { FC, ReactNode } from 'react'
import { IconSize } from 'vanilla-extract-config/sprinkles'
import { useLocale } from '../../hooks/useLocale'
import { Button } from '../button'
import { PopupCardTrigger } from '../popup-card'
import { iconWrapperCx } from './DocsLink.css'

export interface DocsPopupLinkProps {
  helpPopupContent: ReactNode
  size?: IconSize
}

const helpIconLabel = {
  en: 'Help',
  ja: 'ヘルプ',
}
export const DocsPopupLink: FC<DocsPopupLinkProps> = ({ helpPopupContent, size = 'sm' }) => {
  const { locale } = useLocale()

  return (
    <PopupCardTrigger popupContents={helpPopupContent} placement="top">
      <Button
        variant="text"
        size="sm"
        color="primary"
        className={iconWrapperCx}
        tabIndex={-1}
        aria-label={helpIconLabel[locale]}
      >
        <HelpIcon size={size} color="colorsTextNeutralDefaultLowContrast" />
      </Button>
    </PopupCardTrigger>
  )
}
