import { useId } from 'react-aria'
import { FieldWrapperProps } from '../types'

export function useFieldWrapperProps<T>(
  props: T & Omit<FieldWrapperProps, 'id'> & { id?: string }
) {
  const {
    className,
    id: propsId,
    label,
    hideLabel,
    helpText,
    isError,
    isDisabled,
    size,
    'data-testid': dataTestId,
    'data-cy': dataCy,
    labelProps,
    inputWrapperProps,
    style,
    ...rest
  } = props
  const id = useId(propsId)
  const errorId = `${id}-error`
  return {
    fieldWrapperProps: {
      className,
      errorId,
      helpText,
      hideLabel,
      id,
      isDisabled: Boolean(isDisabled),
      isError: Boolean(isError),
      label,
      labelProps,
      inputWrapperProps,
      size,
      style,
    },
    testProps: {
      'data-testid': dataTestId,
      'data-cy': dataCy,
    },
    ...rest,
  }
}
