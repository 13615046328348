import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type InfoFillIconProps = SvgIconProps & TestProps

export const InfoFillIcon: FC<InfoFillIconProps> = ({
  color = 'inherit',
  size = 'md',
  ...props
}) => (
  <BaseIconWrapper {...props} data-testid="info-fill-icon" color={color} size={size}>
    <path
      d="M8.00002 1C4.13402 1 1 4.134 1 7.99997C1 11.8659 4.13402 15 8.00002 15C11.866 15 15 11.8659 15 7.99997C15 4.134 11.866 1 8.00002 1ZM8.79275 4.82942C8.79275 5.26722 8.43783 5.62213 8.00003 5.62213C7.56223 5.62213 7.20732 5.26722 7.20732 4.82942C7.20732 4.39161 7.56223 4.03671 8.00003 4.03671C8.43783 4.03671 8.79275 4.39161 8.79275 4.82942ZM6.41472 6.41484H6.9432H8.00015C8.29203 6.41484 8.52863 6.65144 8.52863 6.94331V10.6426H9.0571H9.58558V11.6996H9.0571H8.00015H6.9432H6.41472V10.6426H6.9432H7.47167V7.47179H6.9432H6.41472V6.41484Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
