import { FC } from 'react'
import { TestProps } from 'typ'
import { BaseIconWrapper } from '../common/BaseIconWrapper'
import { SvgIconProps } from './types'

export type HelpIconProps = SvgIconProps & TestProps

export const HelpIcon: FC<HelpIconProps> = ({ color = 'inherit', size = 'md', ...props }) => (
  <BaseIconWrapper {...props} data-testid="help-icon" color={color} size={size}>
    <path
      d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M8.60027 11.1824C8.60027 11.5138 8.33164 11.7824 8.00027 11.7824C7.6689 11.7824 7.40027 11.5138 7.40027 11.1824C7.40027 10.8511 7.6689 10.5824 8.00027 10.5824C8.33164 10.5824 8.60027 10.8511 8.60027 11.1824Z"
      fill="inherit"
      stroke="transparent"
    />
    <path
      d="M7.29926 5.29926C7.20147 5.39706 7.1 5.59249 7.1 6C7.1 6.33137 6.83137 6.6 6.5 6.6C6.16863 6.6 5.9 6.33137 5.9 6C5.9 5.40751 6.04853 4.85294 6.45074 4.45074C6.85294 4.04853 7.40751 3.9 8 3.9C8.58914 3.9 9.24257 4.04612 9.75911 4.47656C10.2949 4.92304 10.6 5.60569 10.6 6.49997C10.6 7.02961 10.4145 7.43897 10.1026 7.73784C9.81919 8.00949 9.46595 8.15354 9.21068 8.24926C9.1374 8.27675 9.07272 8.30033 9.01508 8.32135C8.83353 8.38755 8.72175 8.42831 8.6305 8.48534C8.6166 8.49403 8.60675 8.50111 8.6 8.50642V9.49997C8.6 9.83134 8.33137 10.1 8 10.1C7.66863 10.1 7.4 9.83134 7.4 9.49997V8.49997C7.4 8.26788 7.46017 8.05208 7.58142 7.8637C7.69829 7.68214 7.85205 7.55678 7.9945 7.46774C8.2047 7.33637 8.47572 7.23894 8.67414 7.16761C8.71622 7.15248 8.75504 7.13853 8.78932 7.12568C9.03405 7.0339 9.18081 6.95919 9.27236 6.87146C9.33549 6.81095 9.4 6.72031 9.4 6.49997C9.4 5.89428 9.20511 5.57694 8.99089 5.39843C8.75743 5.20388 8.41086 5.1 8 5.1C7.59249 5.1 7.39706 5.20147 7.29926 5.29926Z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="inherit"
      stroke="transparent"
    />
  </BaseIconWrapper>
)
