import clsx from 'clsx'
import { HelpIcon } from 'icons/HelpIcon'
import { ReactNode, forwardRef } from 'react'
import { PressEvents, usePress } from 'react-aria'
import { Label as AriaLabel, LabelProps as AriaLabelProps } from 'react-aria-components'
import { TestProps } from 'typ'
import { useLocale } from '../../hooks/useLocale'
import { DocsLink } from '../docs-link'
import { iconWrapperCx } from '../docs-link/DocsLink.css'
import { DocsPopupLink } from '../docs-link/DocsPopupLink'
import { Flex } from '../flex'
import { TooltipTrigger } from '../tooltip'
import { labelCx, optionalCx } from './Label.css'

export interface LabelProps extends TestProps, AriaLabelProps, PressEvents {
  isOptional?: boolean
  helpHref?: string
  helpPopupContent?: ReactNode
  helpTooltipContent?: ReactNode
  className?: string
  children?: React.ReactNode
  isHidden?: boolean
}

const optionaText = {
  en: 'optional',
  ja: '任意',
}

export const Label = forwardRef<HTMLLabelElement, LabelProps>(
  (
    {
      className,
      isOptional = false,
      helpHref,
      helpTooltipContent,
      helpPopupContent,
      children,
      isHidden,
      ...props
    },
    ref
  ) => {
    const { pressProps } = usePress(props)
    const { locale } = useLocale()
    return (
      <Flex className={clsx([labelCx({ isHidden }), className])}>
        <AriaLabel className={clsx([labelCx({ isHidden })])} {...pressProps} ref={ref}>
          {children}
          {isOptional && <div className={optionalCx}>({optionaText[locale]})</div>}
        </AriaLabel>
        {helpHref && <DocsLink href={helpHref} />}
        {!isHidden && !!helpTooltipContent && (
          <TooltipTrigger tooltipContent={helpTooltipContent} placement="top">
            <div className={iconWrapperCx}>
              <HelpIcon size="sm" />
            </div>
          </TooltipTrigger>
        )}
        {!isHidden && !helpHref && !helpTooltipContent && !!helpPopupContent && (
          <DocsPopupLink helpPopupContent={helpPopupContent} size="sm" />
        )}
      </Flex>
    )
  }
)

Label.displayName = 'Label'
