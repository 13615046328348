import clsx from 'clsx'
import { forwardRef } from 'react'
import { Popover as AriaPopover, PopoverProps as AriaPopoverProps } from 'react-aria-components'
import { TestProps } from 'typ'

import { wrapperCx } from './Popover.css'

export interface PopoverProps extends TestProps, AriaPopoverProps {}

export const Popover = forwardRef<HTMLElement, PopoverProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <AriaPopover
      ref={forwardedRef}
      offset={2}
      className={({ isEntering, isExiting, placement }) =>
        clsx([wrapperCx({ isEntering, isExiting, placement: placement ?? 'bottom' }), className])
      }
      {...props}
    >
      {children}
    </AriaPopover>
  )
)

Popover.displayName = 'Popover'
